import React from 'react';
import Helmet from 'react-helmet';
import styles from './Layout.module.scss';

import ProjectBanner from "./ProjectBanner";

const Layout = ({ children, title, description }) => (
  <div>
    <div className={styles.layout}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </div>
  </div>
);

export default Layout;
